
import Vue from "vue";
import GoogleMap from "@/components/modules/GoogleMap.vue";

export default Vue.extend({
  name: "ExtendedDoctorCard",
  components: { GoogleMap },
  props: {
    doctorProps: {
      type: Object,
      required: true,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    openMapDoctorUuid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      defaultUserImg: require("@/assets/images/default-user.jpg"),
      showDoctorBio: false,
      isDoctorBioLoading: false,
      doctorBio: "",
      isMapOpen: false,
    };
  },
  computed: {
    docImg() {
      return this.doctorProps.avatar ?? this.defaultUserImg;
    },
    isAnotherMapOpen() {
      return (
        this.openMapDoctorUuid !== "" &&
        this.openMapDoctorUuid !== this.doctorProps.uuid
      );
    },
    isDesktop() {
      return window.innerWidth >= 1023;
    },
    slots() {
      const slotsArrKeys = this.slotsKeys;
      return slotsArrKeys.map((day) => {
        let formatedDay: string;
        const isToday = this.$dayjs(day).isToday();
        const isTomorrow = this.$dayjs(day).isTomorrow();

        if (isToday) {
          formatedDay = "Today";
        } else if (isTomorrow) {
          formatedDay = "Tomorrow";
        } else {
          formatedDay = this.$dayjs(day).format("D MMM");
        }

        return {
          day: formatedDay,
          date: this.$dayjs(day).format("YYYY-MM-DD"),
          times: this.doctorProps?.slots[day],
          timesCount: this.doctorProps?.slots[day].length,
        };
      });
    },
    slotsKeys() {
      return Object.keys(this.doctorProps?.slots);
    },
  },
  methods: {
    selectDocWithScroll(date) {
      this.$emit("select-doctor", this.doctorProps, date);
    },
    toggleMap() {
      this.$emit("opened-map", this.isMapOpen ? "" : this.doctorProps.uuid);
      this.isMapOpen = !this.isMapOpen;
    },
    async toggleDoctorBio() {
      if (!this.showDoctorBio) {
        this.isDoctorBioLoading = true;
        // Function to fetch doctor bio

        const payload = { externalId: this.doctorProps.externalId };

        const bio = await this.$store.dispatch("LOAD_DOCTOR_BIO", payload);

        if (!!bio) {
          this.doctorBio = bio;
          this.showDoctorBio = true;
        } else {
          this.$eventBus.$emit("emit-error", {
            state: true,
            text: `Something went wrong while loading bio of ${this.doctorProps.name}`,
          });
        }

        this.isDoctorBioLoading = false;
      } else {
        this.showDoctorBio = false;
      }
    },
  },
});
